import React, { useContext, useEffect } from "react";
import "./style.css";
import StepsLayout from "../Steps/stepLayout";
import { useParams } from "react-router-dom";
import MainContext from "../context/mainContext";

export default function MainPage() {
    const { service } = useParams(); 

    const services = [
        {
            name: "Ask An Imam",
            slug: "ask-an-imam"
        },
        {
            name: "New Muslim Initiation",
            slug: "new-muslim-initiation"
        },
        {
            name: "Education",
            slug: "education"
        },
        {
            name: "Marriage",
            slug: "marriage"
        },
        {
            name: "Recreational Activities",
            slug: "recreational-activities"
        },
        {
            name: "Funerals",
            slug: "funerals"
        }
    ];

    const { selectedService, setSelectedService } = useContext(MainContext);

    useEffect(() => {
        // Find the service that matches the slug from the URL parameter
        const foundService = services.find(s => s.slug === service);
        
        // If a matching service is found, set it in the context
        if (foundService) {
            setSelectedService(foundService.name); // You can set either the name or the whole object
        }
    }, [service, setSelectedService, services]);

    return (
        <div className="mainPage">
            <StepsLayout />
        </div>
    );
}

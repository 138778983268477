import React, { useState, useRef, useContext } from "react";
import MainContext from "./mainContext";
import { useNavigate } from 'react-router-dom';
import { Url } from "../env";
import Calendar from 'react-calendar';

const MainContextProvider = ({ children }) => {

    const [currentStep, setCurrentStep] = useState(0);
    const [selectedService,setSelectedService]= useState("");

    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
    const [popup1State, setPopup1State] = useState({
        state: true,
        heading: "",
        message: "",
    });
    const [showPopup1, setShowPopup1] = useState(false);
    const [popup1Head, setPopup1Head] = useState("");
    const [popup1Mess, setPopup1Mess] = useState("");

    const closePopup1 = () => {
        setShowPopup1(false);
        setPopup1Head("");
        setPopup1Mess("")
    }



    const [slots, setSlots] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [showSuccess,setSuccess]= useState(false);



    const fetchSlots = async (date) => {
        setSelectedDate(date)
        setSelectedTimeSlot("");
        setSlots(null)
        try {
            setLoading(true);

            // Payload data
            const payload = {
                person: "icdc imam",  // This can be dynamic as well
                date: date // Date will be passed from the function argument
            };

            const response = await fetch(`${Url}service-slot/Check`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)  // Convert the payload object to JSON string
            });

            if (!response.ok) {
                throw new Error('Failed to fetch slots for the given date');
            }

            const data = await response.json();
            console.log(data)
            setSlots(data.data.slots);  // Assuming the response has `dateSlot`
            console.log(data.data.slots);
            setLoading(false);

        } catch (error) {
            setPopup1State({ state: true, message: "Alert", heading: "Please Fill all Mandatory Fields." })
            setError(error.message);
            setLoading(false);
        }
    };






    const serviceReservation = async () => {
        try {
            setLoading(true);
            const payload = {
                "firstName": formData.first_name,
                "lastName": formData.last_name,
                "email": formData.email,
                "address": formData.address,
                "phone": formData.phone,
                "zip": formData.zip,
                "city": formData.city,
                "state": formData.state,
                "person": "icdc imam",
                "selectedDate": selectedDate,
                "selectedSlot": [selectedTimeSlot],
                "service": selectedService
              }              
            const response = await fetch(`${Url}service-reservation/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                setSuccess(true)
                
            }else{
                throw new Error('Failed to fetch slots for the given date');
            }

            const data = await response.json();
            console.log(data)
            setLoading(false);

        } catch (error) {
            // setPopup1State({ state: true, message: "Alert", heading: "Please Fill all Mandatory Fields." })
            setError(error.message);
            setLoading(false);
        }
    };






    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        phone: "",
        zip: "",
        state: "",
        city: ""
    });
    const [errors, setErrors] = useState({});

    const handleFormInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const validate = () => {
        const newErrors = {};
        if (!formData.first_name) newErrors.first_name = "First Name is required";
        if (!formData.last_name) newErrors.last_name = "Last Name is required";
        if (!formData.email) newErrors.email = "Email Address is required";
        if (!formData.address) newErrors.address = "Address is required";
        if (!formData.phone) newErrors.phone = "Phone Number is required";
        if (!formData.zip) newErrors.zip = "Zip Code is required";
        if (!formData.state) newErrors.state = "State is required";
        if (!formData.city) newErrors.city = "City is required";
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        const newErrors = validate();
        if (Object.keys(newErrors).length === 0) {
            console.log(formData);
            setCurrentStep(currentStep + 1) // Log form data to console
        } else {
            console.log(newErrors)
            setErrors(newErrors);
            setShowPopup1(true)
            setPopup1Head("Alert")
            setPopup1Mess("Please Fill All Mandatory Fields.")
        }
    };


    function convertDateString(dateStr) {
        const [day, month, year] = dateStr.split('-');
        
        const monthNames = [
            "January", "February", "March", "April", "May", "June", 
            "July", "August", "September", "October", "November", "December"
        ];
    
        const monthName = monthNames[parseInt(month) - 1];

        return `${day} of ${monthName} ${year}`;
    }

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };
    return (



        <MainContext.Provider value={{
            selectedService,setSelectedService,
            selectedDate,selectedTimeSlot,
            currentStep, setCurrentStep,
            loading, slots, fetchSlots, selectedTimeSlot, setSelectedTimeSlot,
            formData, setFormData, errors, setErrors, handleFormInputChange, validate, handleSubmit,
            popup1State, setPopup1State,
            showPopup1, popup1Head, popup1Mess, closePopup1,
            convertDateString,formatDate,serviceReservation,
            showSuccess,setSuccess
        }}>
            {children}
        </MainContext.Provider>
    )
}

export default MainContextProvider;
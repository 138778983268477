import React, { useContext } from "react";
import MainContext from "../context/mainContext";
import Btn1 from "../components/Buttons/button1";
import { milestones } from "../logic/steps";
import "./style.css";
import MessagePopup from "../components/message";
import correct from "../assets/correct.png";
import Loader from "../components/loader";

export default function StepsLayout() {
    const { currentStep, setCurrentStep, handleSubmit, popup1State, setPopup1State,
        showPopup1, popup1Head, popup1Mess, closePopup1, serviceReservation,
        showSuccess,setSuccess,loading,selectedDate,
        selectedTimeSlot,selectedService

    } = useContext(MainContext);

    const currentMilestone = milestones.find(milestone => milestone.step === currentStep);

    return (
        <div className="layout_steps">
            <div className="subHeader">
                <div className="selectedServiceName">
                    <p className="label">Selected Service :</p>
                    <h3>{selectedService}</h3>
                </div>
                {!showSuccess ? <div className="headerBtns" style={{ display: "flex", gap: "10px" }}>
                    {/* Conditionally render buttons based on the current milestone */}
                    {currentMilestone?.buttons.includes("prev") && (
                        <Btn1
                            text={"Previous"}
                            width={"60px"}
                            onclick={() => setCurrentStep(currentStep - 1)}
                        />
                    )}
                    {currentMilestone?.buttons.includes("next") && (
                        currentStep === 1 ? <Btn1
                            text={"Next"}
                            width={"60px"}
                            onclick={handleSubmit}
                        /> : <Btn1
                            text={"Next"}
                            width={"60px"}
                            onclick={() => { setCurrentStep(currentStep + 1) }}
                        />
                    )}
                    {currentMilestone?.buttons.includes("confirm") && (
                        <Btn1
                            text={"Confirm Reservation"}
                            width={"200px"}
                            onclick={() => { serviceReservation("Education") }}
                        />
                    )}
                </div> :
                    <div className="confirmation_icon">
                        <img src={correct} alt="" srcset="" />
                        <div className="content_confirmation">
                            <span>Success</span>
                            <span>Your Reservation Has Been Done.</span>
                        </div>
                    </div>
                }
            </div>

            {/* Render the component corresponding to the current step */}
            <div className="step_component">
                {currentMilestone?.component}
            </div>
            {showPopup1 ? <MessagePopup heading={popup1Head} message={popup1Mess} onClick={closePopup1} /> : <></>}
            {loading?<Loader/>:<></>}
        </div>
    );
}

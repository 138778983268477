import React, {useContext} from "react";
import "./style.css";
import Calendar0 from "../calendar/calendar";
import Loader from "../components/loader";
import MainContext from "../context/mainContext";


const slots_dummy = [
    {
        slot:"10:00 AM - 12:00 PM",
        availability:"no"
    },
    {
        slot:"10:00 AM - 12:00 PM",
        availability:"no"
    },
    {
        slot:"10:00 AM - 12:00 PM",
        availability:"no"
    },
   
]

export default function Step1() {
    const {loading,slots,selectedTimeSlot,setSelectedTimeSlot} = useContext(MainContext)
    return (
        <div className="step1">
            <div className="calendarSection">
                <p className="label">Please Select Date</p>
                <Calendar0/>
            </div>
            <div className="slotsSection">
            <p className="label">Please Select Time Slot</p>
                  {slots ? <div className="slots">
                    {slots.map((item,index)=>(
                         <div onClick={()=>{setSelectedTimeSlot(item.slot)}} className={`slotInd ${item.availability==="no"?"deactivate":""} ${selectedTimeSlot===item.slot?"selected":""}`}>
                         {item.slot}
                     </div>
                    ))}
                 </div>:<div className="slots">
                    {slots_dummy.map((item,index)=>(
                         <div  className={`slotInd ${item.availability==="no"?"deactivate":""}`}>
                         {item.slot}
                     </div>
                    ))}
                 </div>}
                
                
            </div>

        </div>
    )
}
import React from "react";
import "./style.css";
import loader from "../assets/loader.gif"

export default function Loader() {
    return(
        <div className="loader_overlay">
            <img src={loader} alt="" srcset="" />
        </div>
    )
}
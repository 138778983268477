import React from "react";
import "./style.css";

export default function Btn1({text,width,onclick,}) {
    return(
        <div  onClick={onclick} className="btn1" style={{width:width}}>
            <span className="btn-button-text">
                {text}
            </span>
        </div>
    )
}
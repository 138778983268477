import React, { useState } from 'react';

const CustomInput = ({
  label,
  value,
  onChange,
  type = 'text',
  placeholder = '',
  required = false,
  errorMessage = '',
  width,
  maxLen = null,
  class_name,
  name
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (required && !value) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // If type is number, limit the input to the maxLen digits
    if (type === 'number') {
      if (!maxLen || inputValue.length <= maxLen) {
        onChange(e); 
      }
    } else {
      onChange(e); // For non-number types, just pass the event up
    }
    
  };

  return (
    <div className={`custom-input  ${class_name}` } style={{width:width}}>
      {label && <label >{label}</label>}
      <input
        className={`input-field ${!isValid ? 'error' : ''}`}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        required={required}
        maxLength={type === 'number' ? maxLen : null} // Apply maxLen for number type
      />
      {!isValid && errorMessage && (
        <span className="error-message" >
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default CustomInput;

import { BrowserRouter, Routes, Route, useLocation, useParams } from "react-router-dom";
import HeaderBar from "../components/header/header";
import MainPage from "../Pages/mainPage";

function MainPageWrapper() {
    const { service } = useParams(); // Extract the URL parameter
    return <MainPage service={service} />;
}

function Routing() {
    return (
        <>
            <HeaderBar />
            <Routes>
                <Route path="/:service" element={<MainPageWrapper />} />
            </Routes>
        </>
    );
}

export default Routing;

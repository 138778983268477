import React from "react";
import "./style.css";
import loader from "../assets/loader.gif";

export default function MessagePopup({heading,message,onClick}) {
    return(
        <div className="loader_overlay">
             <div className="message_box">
                <h3>{heading}</h3>
                <p>{message}</p>
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <div></div>
                <button onClick={onClick}>OK</button>
                </div>
             </div>
        </div>
    )
}
import React,{useContext} from "react";
import "./style.css";
import "../App.css"
import MainContext from "../context/mainContext";

export default function Step3({}) {
    const {formData, selectedDate,selectedTimeSlot,convertDateString} = useContext(MainContext);
    return(
        <div className="summary_page container">
            <div className="row">
                <div className="selectedDT col-12">
                    <span>Selected Date</span>
                    <span>{convertDateString(selectedDate)}</span>
                </div>
                <div className="selectedDT col-12">
                    <span>Selected Time</span>
                    <span>{selectedTimeSlot}</span>
                </div>

                <div className="otherInfo col-12">
                    <span className="lableHead">Full Name</span>
                    <span className="valueInfo">{formData.first_name} {formData.last_name}</span>
                </div>
                <div className="otherInfo col-8">
                <span className="lableHead">Email Address</span>
                <span className="valueInfo">{formData.email}</span>
                </div>
                <div className="otherInfo col-4">
                <span className="lableHead">Phone</span>
                <span className="valueInfo">{formData.phone} </span>
                </div>
                <div className="otherInfo col-12">
                <span className="lableHead">Address</span>
                <span className="valueInfo">{formData.address}</span>
                </div>
                <div className="otherInfo col-4">
                    <span className="lableHead">Zip Code</span>
                    <span className="valueInfo">{formData.zip}</span>
                </div>
                <div className="otherInfo col-4">
                <span className="lableHead">City</span>
                <span className="valueInfo">{formData.city}</span>
                </div>
                <div className="otherInfo col-4">
                <span className="lableHead">State</span>
                <span className="valueInfo">{formData.state}</span>
                </div>

            </div>
        </div>
    )
}
import Step1 from "../Steps/step1"
import Step2 from "../Steps/step2"
import Step3 from "../Steps/Step3"

export const milestones = [
    {
        id: 1,
        step:0,
        name:"Date & Time Selection",
        component:<Step1/>,
        buttons:["next"]
    },
    {
        id: 2,
        step:1,
        name:"Information",
        component:<Step2/>,
        buttons:["next","prev"]
    },
    {
        id: 3,
        step:2,
        name:"Summary",
        component:<Step3/>,
        buttons:["prev","confirm"]
    },
]
import logo from './logo.svg';
import './App.css';
import Calendar0 from './calendar/calendar';
import 'react-calendar/dist/Calendar.css';
import Routing from './routing/routing';
import { BrowserRouter } from 'react-router-dom'
import MainContextProvider from './context/mainContextProvider';

function App() {
  return (
    <MainContextProvider>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </MainContextProvider>
  );
}

export default App;

import React ,{useState,useContext} from "react";
import './header.css';
import { CaretDownFilled, MenuOutlined ,CloseOutlined} from "@ant-design/icons";
import phone_p from '../../assets/phone_p.png';
import logo from "../../assets/logo.png";


function HeaderBar({ id }) {
    const [isActiveSubUl, setIsActiveSubUl] = useState(null);
    const [isMobileSideMenuOpened, setMobileSideMenuOpened] = useState(false);

    const makePhoneCall = () => {
        window.location.href = 'tel:14845571456'; // Phone number
    };

    // List of menu items
    const menuItems = [
        { 
            name: "Home", 
            link: "https://icdcmasjid.org", 
            subMenu: [] 
        },
        { 
            name: "Events", 
            link: "https://event.icdcmasjid.org/", 
            subMenu: [
                { name: "Community", link: "https://event.icdcmasjid.org/" },
                { name: "Religious", link: "https://event.icdcmasjid.org/" }
            ] 
        },
        { name: "Become A Volunteer", link: "https://icdcmasjid.org/become-a-volunteer/", subMenu: [] },
        { name: "Services", link: "https://icdcmasjid.org/services/", subMenu: [] },
         { 
            name: "About Us", 
            link: "https://icdcmasjid.org/about-us/", 
            subMenu: [
                { name: "Our Team", link: "https://icdcmasjid.org/our-team/" },
                { name: "Gallery", link: "https://icdcmasjid.org/gallery/" },
               
            ] 
        },
      
        { name: "Contact Us", link: "https://icdcmasjid.org/contact-us/", subMenu: [] }
    ].filter(Boolean); // Filter to remove false values (e.g., when id === "cbx-2")

    return (
     
          <>
                <div className="header">
                    <div className="logoHeader">
                    <a href="https://icdcmasjid.org/">
                                <img src={logo} alt="Logo" />
                            </a> 
                    </div>

                    <div className="tabsHeader">
                        <nav>
                            <ul>
                                {menuItems.map((item, index) => (
                                    <li
                                        key={index}
                                        onMouseLeave={() => setIsActiveSubUl(null)}
                                        onMouseEnter={() => setIsActiveSubUl(index)}
                                        className={isActiveSubUl === index ? "activeDropdown" : ""}
                                    >
                                        <a href={item.link}>
                                            {item.name}
                                            {item.subMenu.length > 0 && <span className="sub-arrow"><CaretDownFilled /></span>}
                                        </a>

                                        {item.subMenu.length > 0 && (
                                            <ul className="sub-menu">
                                                {item.subMenu.map((subItem, subIndex) => (
                                                    <li key={subIndex} className="sub-menu-item">
                                                        <a href={subItem.link}>{subItem.name}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>

                    <div className="buttonHeader">
                        <a className="inquiry-button" onClick={makePhoneCall}>
                            <span className="inq-button-text">1 484 557 1456</span>
                        </a>
                        <a className="menu-icon" onClick={() => setMobileSideMenuOpened(true)}>
                            <MenuOutlined />
                        </a>
                    </div>
                </div>
           
                <div className={isMobileSideMenuOpened ? "mobileViewSideHeader active" : 'mobileViewSideHeader'}>
                    <div onClick={() => setMobileSideMenuOpened(false)} className="closeButton">
                        <CloseOutlined />
                    </div>

                    <div className="sub_header_mobile">
                        <div className="contact" onClick={makePhoneCall}>
                            <span className="contactIcon"><img src={phone_p} alt="phone" /></span>
                            <span className="contactNumber">1 484 557 1456</span>
                        </div>
                    </div>

                    <div className="mobile_header_links">
                        <ul>
                            {menuItems.map((item, index) => (
                                <li key={index}>
                                    <a href={item.link}>{item.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
          </>
          
    );
}

export default HeaderBar;
